// src/components/ChangePassword.tsx
import React, { useState } from "react";
import { updatePassword } from 'aws-amplify/auth';
import { PasswordField, Text } from '@aws-amplify/ui-react';
import { Alert } from '@aws-amplify/ui-react';
interface ChangePasswordProps {
  onSuccess: () => void;
  onFailure: (message: string) => void;
}

const ChangePassword: React.FC<ChangePasswordProps> = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isChanging, setIsChanging] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariation, setAlertVariation] = useState<"error" | "success">("error");


  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsChanging(true);

    try {
      await updatePassword({
        oldPassword,
        newPassword
      });
      setOldPassword("");
      setNewPassword("");
      setAlertMessage("Password changed successfully.");
      setAlertVariation("success");
    } catch (error) {
      console.error("Error changing password:", error);
      setAlertMessage("Failed to change password. Please try again.");
      setAlertVariation("error");
    } finally {
      setIsChanging(false);
    }
  };

  return (
    <form onSubmit={handleChangePassword} className="tw-mt-4">
        {alertMessage && (
        <Alert
          variation={alertVariation}
          isDismissible={false}
          hasIcon={true}
          heading={alertVariation === "error" ? "Error" : "Success"}
        >
          {alertMessage}
        </Alert>
      )}

    {/* old password input */}
      <div className="tw-mb-4">
        <div className="tw-relative">
        <PasswordField
            label="Current Password"
            name="oldPassword"
            isRequired={true}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            descriptiveText={
              <Text
                as="span"
                color="rebeccapurple"
                fontStyle="italic"
              >
              </Text>
            }
          />
        </div>
      </div>
      <div className="tw-mb-4">
        <div className="tw-relative">
        <PasswordField
      label="New Password"
      name="newPassword"
      isRequired={true}
      value={newPassword}
      onChange={(e) => setNewPassword(e.target.value)}
      descriptiveText={
        <Text
          as="span"
          color="rebeccapurple"
          fontStyle="italic"
        >
          Password length must be greater than 5 characters
        </Text>
      }
    />
        </div>
      </div>
      <button
        type="submit"
        className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded hover:tw-bg-blue-600"
        disabled={isChanging}
      >
        {isChanging ? "Changing..." : "Change Password"}
      </button>
    </form>
  );
};

export default ChangePassword;
