import React from 'react';
import { Mail, Loader2 } from 'lucide-react';
import { AuthFormProps } from '../types';

export const ForgotPasswordForm: React.FC<AuthFormProps> = ({
  email,
  setEmail,
  loading,
  onSubmit,
  setAuthState,
}) => (
  <form onSubmit={onSubmit} className="tw-space-y-4">
    <div>
      <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Email</label>
      <div className="tw-mt-1 tw-relative">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          className="tw-appearance-none tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-primary-500 focus:tw-border-primary-500"
          required
        />
        <Mail className="tw-absolute tw-right-3 tw-top-2.5 tw-h-5 tw-w-5 tw-text-gray-400" />
      </div>
    </div>

    <div>
      <button
        type="submit"
        disabled={loading}
        className="tw-w-full tw-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-primary-600 hover:tw-bg-primary-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-primary-500"
      >
        {loading ? <Loader2 className="tw-h-5 tw-w-5 tw-animate-spin" /> : 'Send reset link'}
      </button>
    </div>

    <p className="tw-mt-2 tw-text-center tw-text-sm tw-text-gray-600">
      Remember your password? Go back to  {' '}
      <button
        type="button"
        onClick={() => setAuthState('signIn')}
        className=" tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-primary-600 hover:tw-bg-primary-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-primary-500"
      >
        Sign in
      </button>
    </p>
  </form>
);