import { signInWithRedirect } from 'aws-amplify/auth';

export const signInWithGoogle = () => {
  return signInWithRedirect({ provider: 'Google' });
};

export const signInWithFacebook = () => {
  return signInWithRedirect({ provider: 'Facebook' });
};

