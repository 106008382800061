class TrackingService {
    static trackGAEvent(category: string, action: string, label?: string, value?: number) {
      if (window.gtag) {
        window.gtag('event', action, {
          event_category: category,
          event_label: label,
          value: value,
        });
      } else {
        console.warn('Google Analytics is not loaded');
      }
    }
  
    static trackChartbeatEvent(event: string, data: any) {
      if (window._cbq) {
        window._cbq.push([event, data]);
      } else {
        console.warn('Chartbeat is not loaded');
      }
    }
  }
  
  export default TrackingService;