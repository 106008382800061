// src/components/UpdateEmail.tsx
import React, { useState, useEffect } from 'react';
import { 
  getCurrentUser, 
  updateUserAttributes,
  confirmUserAttribute,
  fetchUserAttributes
} from 'aws-amplify/auth';
import { Mail } from 'lucide-react';

interface UpdateEmailProps {
  onSuccess: () => void;
  onFailure: (message: string) => void;
}

const UpdateEmail: React.FC<UpdateEmailProps> = ({ onSuccess, onFailure }) => {
  const [currentEmail, setCurrentEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchCurrentEmail();
  }, []);

  const fetchCurrentEmail = async () => {
    try {
      const attributes = await fetchUserAttributes();
      setCurrentEmail(attributes.email || '');
    } catch (error) {
      console.error('Error fetching email:', error);
    }
  };

  const handleUpdateEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsUpdating(true);
    setMessage('');

    try {
      await updateUserAttributes({
        userAttributes: {
          email: newEmail
        }
      });
      setIsVerifying(true);
      setMessage('Verification code sent to your new email address. Please check your inbox. ' + newEmail);
    } catch (error) {
      console.error('Error updating email:', error);
      setMessage('Failed to update email. Please try again.');
      onFailure('Failed to update email. Please try again.');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleVerifyEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsUpdating(true);
    setMessage('');

    try {
      await confirmUserAttribute({
        userAttributeKey: 'email',
        confirmationCode: verificationCode
      });
      setMessage('Email updated successfully!');
      setIsVerifying(false);
      setCurrentEmail(newEmail);
      setNewEmail('');
      setVerificationCode('');
      onSuccess();
    } catch (error) {
      console.error('Error verifying email:', error);
      setMessage('Failed to verify email. Please check the code and try again.');
      onFailure('Failed to verify email. Please check the code and try again.');
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className=" tw-mx-auto tw-bg-white tw-p-8 tw-rounded tw-shadow">

      <div className="tw-mb-6">
        <label className="tw-block tw-text-gray-700 tw-mb-2">Current Email</label>
        <p className="tw-text-gray-600">{currentEmail}</p>
      </div>

      {!isVerifying ? (
        <form onSubmit={handleUpdateEmail}>
          <div className="tw-mb-4">
            <label htmlFor="newEmail" className="tw-block tw-text-gray-700 tw-mb-2">New Email</label>
            <input
              type="email"
              id="newEmail"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              className="tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded tw-focus:outline-none tw-focus:border-blue-500"
              required
            />
          </div>
          <button
            type="submit"
            disabled={isUpdating}
            className="tw-w-full tw-bg-blue-500 tw-text-white tw-py-2 tw-rounded hover:tw-bg-blue-600 tw-transition-colors disabled:tw-bg-gray-400"
          >
            {isUpdating ? 'Updating...' : 'Update Email'}
          </button>
        </form>
      ) : (
        <form onSubmit={handleVerifyEmail}>
          <div className="tw-mb-4">
            <label htmlFor="verificationCode" className="tw-block tw-text-gray-700 tw-mb-2">Verification Code</label>
            <input
              type="text"
              id="verificationCode"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              className="tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded tw-focus:outline-none tw-focus:border-blue-500"
              required
            />
          </div>
          <button
            type="submit"
            disabled={isUpdating}
            className="tw-w-full tw-bg-green-500 tw-text-white tw-py-2 tw-rounded hover:tw-bg-green-600 tw-transition-colors disabled:tw-bg-gray-400"
          >
            {isUpdating ? 'Verifying...' : 'Verify Email'}
          </button>
        </form>
      )}

      {message && (
        <div className={`tw-mt-4 tw-p-3 tw-rounded ${message.includes('success') ? 'tw-bg-green-100 tw-text-green-700' : 'tw-bg-red-100 tw-text-red-700'}`}>
          {message}
        </div>
      )}
    </div>
  );
};

export default UpdateEmail;