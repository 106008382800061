import React, { useState, useEffect, useCallback, useRef, Suspense } from "react";
import { get, post, del } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import config from "../swift-config";

interface Taxonomy {
  taxonomy_id: string;
  taxonomy_name: string;
  taxonomy_type: string;
}

const Taxonomies: React.FC = () => {
  const [followedTaxonomies, setFollowedTaxonomies] = useState<string[]>([]);
  const [taxonomies, setTaxonomies] = useState<Taxonomy[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [pubID, setPubID] = useState<string>("devsdn"); // Initialize as empty

  const toggleTaxonomyRunning = useRef<{ [key: string]: boolean }>({});

  /**
   * Retrieves the publication ID based on the current hostname.
   */
  const retrievePubID = useCallback((): string => {
    let currentConfig;
    let currentPubID = "devsdn";

    if (window.location.hostname === "localhost") {
      console.log("Using localhost config");
      currentConfig = config["dev.summitdaily.com"];
    } else {
      console.log("Using config for", window.location.hostname);
      currentConfig = config[window.location.hostname];
    }

    if (currentConfig && currentConfig.Auth && currentConfig.Auth.pubID) {
      currentPubID = currentConfig.Auth.pubID;
    } else {
      console.error("pubID not found in config for hostname:", window.location.hostname);
    }

    return currentPubID;
  }, []);

  const apiName = "swift-website-samapp"; // As defined in main.tsx
  const version = "v1"; // API version
  const getTaxonomiesPath = "/taxonomies"; // Path to fetch all taxonomies
  const getFollowedTaxonomiesPath = "/user/taxonomies"; // Path to fetch followed taxonomies
  const userTaxonomiesPath = "/user/taxonomies"; // Base path for user taxonomies

  /**
   * Fetches all available taxonomies for the given publication.
   */
  const fetchAllTaxonomies = useCallback(
    async (currentPubID: string) => {
      try {
        const session = await fetchAuthSession();
        const token = session.tokens?.idToken?.toString();

        const restOperation = await get({
          apiName,
          path: version + getTaxonomiesPath,
          options: {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-pubid": currentPubID,
            },
          }
        });
        
        const response = await restOperation.response;
        const json = await response.body.json();
        setTaxonomies(json as Taxonomy[]);
      } catch (err) {
        console.error("Error fetching all taxonomies:", err);
        setError("Failed to load taxonomies.");
      }
    },
    [apiName, version, getTaxonomiesPath]
  );

  /**
   * Fetches the list of taxonomies that the user is currently following.
   */
  const fetchFollowedTaxonomies = useCallback(
    async (currentPubID: string) => {
      try {
        const session = await fetchAuthSession();
        const token = session.tokens?.idToken?.toString();

        const restOperation = await get({
          apiName,
          path: version + getFollowedTaxonomiesPath,
          options: {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-pubid": currentPubID,
            },
          }
        });
        
        const response = await restOperation.response;
        const json = await response.body.json();
        const followed = (json as Taxonomy[]).map((taxonomy) => taxonomy.taxonomy_id);
        setFollowedTaxonomies(followed);
      } catch (err) {
        console.error("Error fetching followed taxonomies:", err);
        setError("Failed to load followed taxonomies.");
      }
    },
    [apiName, version, getFollowedTaxonomiesPath]
  );

  /**
   * Initializes the component by retrieving pubID and fetching taxonomies.
   */
  const initialize = useCallback(async () => {
    try {
      const currentPubID = retrievePubID();
      if (!currentPubID) {
        throw new Error("Publication ID (pubID) is missing.");
      }
      setPubID(currentPubID);
      await Promise.all([fetchAllTaxonomies(currentPubID), fetchFollowedTaxonomies(currentPubID)]);
    } catch (err) {
      console.error("Initialization error:", err);
      setError("Failed to initialize taxonomies.");
    } finally {
      setLoading(false);
    }
  }, [fetchAllTaxonomies, fetchFollowedTaxonomies, retrievePubID]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  /**
   * Handles the follow/unfollow action for a given taxonomy.
   * @param taxonomy The taxonomy to follow or unfollow.
   */
  const toggleTaxonomy = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>, taxonomy: Taxonomy) => {
      event.stopPropagation();
      const taxonomyId = taxonomy.taxonomy_id;

      // Check if the toggle action for this taxonomy is already running
      if (toggleTaxonomyRunning.current[taxonomyId]) {
        console.warn(`toggleTaxonomy is already running for taxonomy ID: ${taxonomyId}`);
        return;
      }

      // Set the running flag to true
      toggleTaxonomyRunning.current[taxonomyId] = true;
      console.log(`toggleTaxonomy called for ${taxonomy.taxonomy_name} (ID: ${taxonomy.taxonomy_id})`);

      const isFollowing = followedTaxonomies.includes(taxonomyId);

      console.log(`Toggling taxonomy: ${taxonomy.taxonomy_name} (ID: ${taxonomy.taxonomy_id})`);
      try {
        const session = await fetchAuthSession();
        const token = session.tokens?.idToken?.toString();
        const apiPath = isFollowing
          ? `${version}${userTaxonomiesPath}/${encodeURIComponent(taxonomyId)}`
          : `${version}${userTaxonomiesPath}`;

        if (isFollowing) {
          console.log("Unfollowing taxonomy:", taxonomyId);
          // Unfollow the taxonomy
          await del({
            apiName,
            path: apiPath,
            options: {
              headers: {
                Authorization: `Bearer ${token}`,
                "x-pubid": pubID,
              },
            }
          });
          setFollowedTaxonomies((prev) => prev.filter((t) => t !== taxonomyId));
        } else {
          console.log("Following taxonomy:", taxonomyId);
          // Follow the taxonomy
          const newTaxonomy = {
            taxonomy_id: taxonomyId,
            taxonomy_name: taxonomy.taxonomy_name,
            taxonomy_type: taxonomy.taxonomy_type,
          };

          await post({
            apiName,
            path: apiPath,
            options: {
              headers: {
                Authorization: `Bearer ${token}`,
                "x-pubid": pubID,
              },
              body: newTaxonomy,
            }
          });
          setFollowedTaxonomies((prev) => [...prev, taxonomyId]);
        }
      } catch (err) {
        console.error(`Error ${isFollowing ? "unfollowing" : "following"} taxonomy:`, err);
        setError(`Failed to ${isFollowing ? "unfollow" : "follow"} taxonomy.`);
        setTimeout(() => setError(null), 2000); // Clear error after 2 seconds
      } finally {
        console.log(`toggleTaxonomy finished for ${taxonomy.taxonomy_name} (ID: ${taxonomy.taxonomy_id})`);
        // Reset the running flag
        toggleTaxonomyRunning.current[taxonomyId] = false;
      }
    },
    [followedTaxonomies, apiName, version, userTaxonomiesPath, pubID]
  );

  return (
    <Suspense fallback={<div className="tw-text-center">Loading...</div>}>
      <div className="tw-max-w-2xl tw-mx-auto tw-bg-white tw-p-8 tw-rounded tw-shadow">
        <h2 className="tw-text-2xl tw-font-bold tw-mb-4">Following</h2>

        {/* Authors Section */}
        <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Authors</h3>
        <div className="tw-flex tw-flex-wrap tw-gap-2 tw-mb-4">
          {taxonomies
            .filter((taxonomy) => taxonomy.taxonomy_type === "staffprofile")
            .map((taxonomy) => (
              <button
                key={taxonomy.taxonomy_id}
                id={`toggle-taxonomy-${taxonomy.taxonomy_id}`}
                onClick={(event) => toggleTaxonomy(event, taxonomy)}
                className={`tw-flex tw-items-center tw-mb-2 tw-px-4 tw-py-2 tw-rounded ${
                  followedTaxonomies.includes(taxonomy.taxonomy_id)
                    ? "tw-bg-blue-200 tw-text-blue-700"
                    : "tw-bg-blue-50 tw-text-black"
                }`}
              >
                <input
                  type="checkbox"
                  id={taxonomy.taxonomy_id}
                  checked={followedTaxonomies.includes(taxonomy.taxonomy_id)}
                  readOnly
                  className="tw-mr-2"
                />
                <label htmlFor={taxonomy.taxonomy_id} className="tw-text-sm tw-font-semibold">
                  {taxonomy.taxonomy_name}
                </label>
              </button>
            ))}
        </div>

        {/* Categories Section */}
        <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Sections</h3>
        <div className="tw-flex tw-flex-wrap tw-gap-2 tw-mb-4">
          {taxonomies
            .filter((taxonomy) => taxonomy.taxonomy_type === "category")
            .map((taxonomy) => (
              <button
                key={taxonomy.taxonomy_id}
                id={`toggle-taxonomy-${taxonomy.taxonomy_id}`}
                onClick={(event) => toggleTaxonomy(event, taxonomy)}
                className={`tw-flex tw-items-center tw-mb-2 tw-px-4 tw-py-2 tw-rounded ${
                  followedTaxonomies.includes(taxonomy.taxonomy_id)
                    ? "tw-bg-green-200 tw-text-green-700"
                    : "tw-bg-green-50 tw-text-black"
                }`}
              >
                <input
                  type="checkbox"
                  id={taxonomy.taxonomy_id}
                  checked={followedTaxonomies.includes(taxonomy.taxonomy_id)}
                  readOnly
                  className="tw-mr-2"
                />
                <label htmlFor={taxonomy.taxonomy_id} className="tw-text-sm tw-font-semibold">
                  {taxonomy.taxonomy_name}
                </label>
              </button>
            ))}
        </div>

        {/* Tags Section */}
        <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Topics</h3>
        <div className="tw-flex tw-flex-wrap tw-gap-2 tw-mb-4">
          {taxonomies
            .filter((taxonomy) => taxonomy.taxonomy_type === "tag")
            .map((taxonomy) => (
              <button
                key={taxonomy.taxonomy_id}
                id={`toggle-taxonomy-${taxonomy.taxonomy_id}`}
                onClick={(event) => toggleTaxonomy(event, taxonomy)}
                className={`tw-flex tw-items-center tw-mb-2 tw-px-4 tw-py-2 tw-rounded ${
                  followedTaxonomies.includes(taxonomy.taxonomy_id)
                    ? "tw-bg-yellow-200 tw-text-yellow-700"
                    : "tw-bg-yellow-50 tw-text-black"
                }`}
              >
                <input
                  type="checkbox"
                  id={taxonomy.taxonomy_id}
                  checked={followedTaxonomies.includes(taxonomy.taxonomy_id)}
                  readOnly
                  className="tw-mr-2"
                />
                <label htmlFor={taxonomy.taxonomy_id} className="tw-text-sm tw-font-semibold">
                  {taxonomy.taxonomy_name}
                </label>
              </button>
            ))}
        </div>

        {error && <div className="tw-mt-4 tw-text-red-500 tw-transition-opacity tw-duration-1000">{error}</div>}
      </div>
    </Suspense>
  );
};

export default Taxonomies;
