// src/components/Header.tsx
import React, { useEffect, useState } from 'react';
import { getCurrentUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { User } from 'lucide-react';

const Header: React.FC<{ parentId: string }> = ({ parentId }) => { // parentId prop
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    checkUser();

    const listener = Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signIn' || payload.event === 'cognitoHostedUI') {
        setIsLoggedIn(true);
      }
      if (payload.event === 'signOut') {
        setIsLoggedIn(false);
      }
    });

    return () => Hub.remove('auth', listener);
  }, []);

  useEffect(() => {
    const parent = document.getElementById(parentId); // Get parent by ID
    if (parent) {
      const themeWrapper = parent.querySelector('[data-amplify-theme="default-theme"]');
      if (themeWrapper) {
        themeWrapper.classList.add('tw-inline-block');
        return () => {
          themeWrapper.classList.remove('tw-inline-block');
        };
      }
    }
  }, [isLoggedIn, parentId]); // Add both to dependency array

  const checkUser = async () => {
    try {
      await getCurrentUser();
      setIsLoggedIn(true);
    } catch (error) {
      setIsLoggedIn(false);
      console.error('User is not logged in:', error);
    }
  };

  return (
    <div className="">
      {isLoggedIn ? (
        <div className="tw-relative">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="tw-flex tw-items-center tw-justify-center tw-w-10 tw-h-10 tw-text-gray-700 tw-rounded-full hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300"
          >
            <User className="tw-w-6 tw-h-6" />
            <span className="tw-sr-only">Open menu</span>
          </button>
          {menuOpen && (
            <ul className="tw-all-unset tw-absolute tw-right-0 tw-mt-2 tw-bg-white tw-text-black tw-rounded tw-shadow-lg tw-py-1 list-none pl-0 tw-z-50">
              <li className="tw-all-unset tw-px-4 tw-py-2 tw-list-none">
                <a href="/myprofile" className="tw-block tw-text-black tw-no-underline tw-cursor-pointer hover:tw-text-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300">Profile</a>
              </li>
              <li className="tw-all-unset tw-px-4 tw-py-2 tw-list-none">
                <a href="/newsletter-subscriptions/" className="tw-block tw-text-black tw-no-underline tw-cursor-pointer hover:tw-text-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300">Newsletters</a>
              </li>
              <li className="tw-all-unset tw-px-4 tw-py-2 tw-list-none">
                <a href="/myprofile#interests" className="tw-block tw-text-black tw-no-underline tw-cursor-pointer hover:tw-text-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300">Interests</a>
              </li>
              <li className="tw-all-unset tw-px-4 tw-py-2 tw-list-none">
                <a href="/myprofile#bookmarks" className="tw-block tw-text-black tw-no-underline tw-cursor-pointer hover:tw-text-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300">Bookmarks</a>
              </li>
              <li className="tw-all-unset tw-px-4 tw-py-2 tw-list-none">
                <a href="/myprofile#readinghistory" className="tw-block tw-text-black tw-no-underline tw-cursor-pointer hover:tw-text-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300">Reading History</a>
              </li>
            </ul>
          )}
        </div>
      ) : (
        <a
          href="/authentication"
          className="tw-inline-block tw-text-black tw-no-underline tw-cursor-pointer hover:tw-text-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300">
          Sign In
        </a>
      )}
    </div>
  );
};

export default Header;
