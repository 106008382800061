import React, { useState, useEffect } from 'react';
import { signIn, signUp, resetPassword, confirmResetPassword, confirmSignUp } from 'aws-amplify/auth';
import { SignInForm } from './forms/SignInForm';
import { SignUpForm } from './forms/SignUpForm';
import { ForgotPasswordForm } from './forms/ForgotPasswordForm';
import { ResetPasswordForm } from './forms/ResetPasswordForm';
import { AuthState } from './types';
import { emailSchema, passwordSchema } from './utils/validation';
// import { signInWithGoogle, signInWithFacebook } from './utils/cognito';
import { useAuth } from './AuthContext';
import { LogOut } from 'lucide-react';

const validateEmail = (email: string): { isValid: boolean; error?: string } => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return { 
      isValid: false, 
      error: 'Invalid email address'
    };
  }
  return { isValid: true };
};

export const Authenticator: React.FC = () => {
  const { isAuthenticated, isLoading, signOut } = useAuth();
  const [authState, setAuthState] = useState<AuthState>('signIn');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [resetCode, setResetCode] = useState('');

  // Clear confirmation message when auth state changes or user becomes authenticated
  useEffect(() => {
    setConfirmationMessage('');
  }, [authState, isAuthenticated]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const signupCode = params.get('signup_code');
    const signupEmail = params.get('signup_email');
    const forgotCode = params.get('forgot_password_code');
    const userEmail = params.get('email');
    const userId = params.get('username');

    if (signupCode && signupEmail) {
      handleSignupConfirmation(signupEmail, signupCode);
    } else if (forgotCode && userEmail && userId) {
      setEmail(userEmail);
      setUsername(userId);
      setResetCode(forgotCode);
      setAuthState('resetPassword');
    }
    // Clean up URL parameters
    window.history.replaceState({}, '', window.location.pathname);
  }, []);

  const handleSignupConfirmation = async (email: string, code: string) => {
    setLoading(true);
    setError('');
    try {
      await confirmSignUp({
        username: email,
        confirmationCode: code,
      });
      setConfirmationMessage('Email verified successfully! You can now sign in.');
      setEmail(email);
      setAuthState('signIn');
    } catch (error: any) {
      if (error.name === 'ExpiredCodeException') {
        setError('Verification code has expired. Please request a new one.');
      } else if (error.name === 'CodeMismatchException') {
        setError('Invalid verification code. Please try again.');
      } else if (error.name === 'UserNotFoundException') {
        setError('User not found. Please sign up again.');
      } else if (error.name === 'NotAuthorizedException') {
        setError('User is already verified. Please sign in.');
      } else if (error.name === 'UserNotConfirmedException') {
        setError('User is not confirmed. Please sign up again.');
      } else if (error.name === 'InvalidParameterException') {
        setError('Invalid email or verification code.');
      } else if (error.name === 'InvalidPasswordException') {
        setError('Invalid password. Please try again.');
      } else if (error.name === 'CodeDeliveryFailureException') {
        setError('Failed to send verification email. Please try again.');
      } else if (error.name === 'InternalErrorException') {
        setError('Failed to verify email. Please try again.');
      } else {
        setError(error.message || 'Failed to verify email. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setConfirmationMessage('');

    const emailValidation = validateEmail(email);
    if (!emailValidation.isValid) {
      setError(emailValidation.error || 'Invalid email format');
      setLoading(false);
      return;
    }

    try {
      await signIn({ username: email, password });
    } catch (error: any) {
      if (error.name === 'UserNotConfirmedException') {
        setAuthState('verifyEmail');
      } else if (error.name === 'UserDisabledException') {
        setError('Your account has been disabled. Please contact support.');
      } else if (error.name === 'NotAuthorizedException') {
        setError('Incorrect email or password');
      } else if (error.name === 'UserNotFoundException') {
        setError('User not found');
      } else if (error.name === 'InvalidParameterException') {
        setError('Invalid email or password');
      } else if (error.name === 'InvalidPasswordException') {
        setError('Invalid password');
      } else {
        setError(error.message || 'An error occurred during sign in');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setConfirmationMessage('');

    const emailValidation = validateEmail(email);
    if (!emailValidation.isValid) {
      setError(emailValidation.error || 'Invalid email format');
      setLoading(false);
      return;
    }

    try {
      const validEmail = emailSchema.parse(email);
      const validPassword = passwordSchema.parse(password);
      
      await signUp({
        username: validEmail,
        password: validPassword,
        options: {
          userAttributes: {
            email: validEmail,
          },
        },
      });

      setConfirmationMessage("We've sent you a verification email, click on the link in the email to continue.");
      setAuthState('checkEmail');
    } catch (error: any) {
      if (error.name === 'UsernameExistsException') {
        setError('An account with this email already exists');
      } else if (error.name === 'InvalidPasswordException') {
        setError('Password must be at least 6 characters long');
      } else if (error.name === 'InvalidParameterException') {
        setError('Invalid email or password');
      } else if (error.name === 'InvalidEmailRoleAccessPolicyException') {
        setError('Invalid email or password');
      } else {
        setError(error.message || 'Failed to create account');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setConfirmationMessage('');

    try {
      const validEmail = emailSchema.parse(email);
      await resetPassword({ username: validEmail });
      setConfirmationMessage("We've sent you a link to reset your password.");
      setAuthState('checkEmail');
    } catch (error: any) {
      setError(error.message || 'Failed to send reset password email');
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setConfirmationMessage('');

    try {
      const validPassword = passwordSchema.parse(password);
      
      await confirmResetPassword({
        username: username || email,
        confirmationCode: resetCode,
        newPassword: validPassword,
      });

      // Automatically sign in after password reset
      await signIn({ 
        username: email,
        password: validPassword
      });
    } catch (error: any) {
      setError(error.message || 'Failed to reset password');
    } finally {
      setLoading(false);
    }
  };

  const renderForm = () => {
    if (isAuthenticated) {
      return (
        <div className="tw-text-center">
          <button
            onClick={signOut}
            className="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-transparent tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-red-600 hover:tw-bg-red-700"
          >
            <LogOut className="tw-w-4 tw-h-4 tw-mr-2" />
            Sign Out
          </button>
        </div>
      );
    }

    switch (authState) {
      case 'signIn':
        return (
          <SignInForm
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            loading={loading}
            error={error}
            rememberMe={rememberMe}
            setRememberMe={setRememberMe}
            onSubmit={handleSignIn}
            setAuthState={setAuthState}
          />
        );
      case 'signUp':
        return (
          <SignUpForm
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            loading={loading}
            error={error}
            onSubmit={handleSignUp}
            setAuthState={setAuthState}
          />
        );
      case 'forgotPassword':
        return (
          <ForgotPasswordForm
            email={email}
            setEmail={setEmail}
            loading={loading}
            error={error}
            onSubmit={handleForgotPassword}
            setAuthState={setAuthState}
          />
        );
      case 'resetPassword':
        return (
          <ResetPasswordForm
            password={password}
            setPassword={setPassword}
            loading={loading}
            error={error}
            onSubmit={handleResetPassword}
          />
        );
      case 'checkEmail':
        return (
          <div className="tw-text-center">
            <p className="tw-text-gray-700">{confirmationMessage}</p>
            <button
              onClick={() => setAuthState('signIn')}
              className=" tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-primary-600 hover:tw-bg-primary-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-primary-500"
            >
              Back to Sign In
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-min-h-screen">
        <div className="tw-animate-spin tw-rounded-full tw-h-12 tw-w-12 tw-border-t-2 tw-border-b-2 tw-border-primary-600"></div>
      </div>
    );
  }

  return (
    <div className="tw-min-h-screen tw-bg-gray-50 tw-flex tw-flex-col tw-justify-center tw-py-12 tw-sm:px-6 tw-lg:px-8">
      <div className="tw-sm:mx-auto tw-sm:w-full tw-sm:max-w-md">
        <h2 className="tw-mt-6 tw-text-center tw-text-3xl tw-font-extrabold tw-text-gray-900">
          {authState === 'signIn'
            ? 'Sign in to your account'
            : authState === 'signUp'
            ? 'Create your account'
            : authState === 'forgotPassword'
            ? 'Reset your password'
            : authState === 'checkEmail'
            ? 'Check your email'
            : 'Set new password'}
        </h2>
      </div>

      <div className="tw-mt-8 tw-sm:mx-auto tw-sm:w-full tw-sm:max-w-md">
        <div className="tw-bg-white tw-py-8 tw-px-4 tw-shadow tw-sm:rounded-lg tw-sm:px-10">
          {confirmationMessage && !isAuthenticated && (
            <div className="tw-mb-4 tw-bg-green-50 tw-border tw-border-green-200 tw-text-green-600 tw-px-4 tw-py-3 tw-rounded-md">
              {confirmationMessage}
            </div>
          )}
          {error && (
            <div className="tw-mb-4 tw-bg-red-50 tw-border tw-border-red-200 tw-text-red-600 tw-px-4 tw-py-3 tw-rounded-md">
              {error}
            </div>
          )}
          {renderForm()}
        </div>
      </div>
    </div>
  );
};