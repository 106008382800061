import { useEffect } from 'react';
import TrackingService from '../services/TrackingService';

const useEventTracking = (elementId: string, gaCategory: string, gaAction: string, chartbeatEvent: string, chartbeatData: any) => {
  useEffect(() => {
    const element = document.getElementById(elementId);
    if (element) {
      const handleClick = () => {
        TrackingService.trackGAEvent(gaCategory, gaAction);
        TrackingService.trackChartbeatEvent(chartbeatEvent, chartbeatData);
      };
      element.addEventListener('click', handleClick);
      return () => {
        element.removeEventListener('click', handleClick);
      };
    }
  }, [elementId, gaCategory, gaAction, chartbeatEvent, chartbeatData]);
};

export default useEventTracking;