
interface OAuthConfig {
  domain: string;
  scopes: string[];
  redirectSignIn: string[];
  redirectSignOut: string[];
  responseType: string;
  providers?: string[];
}

interface AuthConfig {
  mandatorySignIn: boolean;
  region: string;
  userPoolWebClientId: string;
  signUpVerificationMethod?: string;
  pubID: string;
  pubSecondStreetID: string;
  Cognito?: {
    userPoolId: string;
    userPoolClientId: string;
    identityPoolId: string;
    signUpVerificationMethod: string;
    loginWith: {
      email: boolean;
      phone: boolean;
      username: boolean;
      oauth: OAuthConfig;
    };
  };
  oauth?: OAuthConfig;
  identityPoolId?: string;
  clientID?: string;
}

interface AWSPinpointConfig {
  appId: string;
  region: string;
  mandatorySignIn: boolean;
  userPoolId?: string;
  userPoolWebClientId?: string;
  identityPoolId?: string;
}

interface AnalyticsConfig {
  AWSPinpoint: AWSPinpointConfig;
  disabled?: boolean;
  autoSessionRecord?: boolean;
}

interface APIEndpoint {
  name: string;
  endpoint: string;
  region: string;
  authorizationType?: string;
  custom_header?: () => Promise<Record<string, string>>;
}

interface APIConfig {
  endpoints: APIEndpoint[];
}

interface ThemeConfig {
  colors: {
    brand: {
      primary: {
        "10": string;
        "80": string;
        "100": string;
      };
      secondary: {
        "10": string;
        "80": string;
        "100": string;
      };
    };
  };
}

interface SiteConfig {
  Analytics?: AnalyticsConfig;
  Auth: AuthConfig;
  API?: APIConfig;
  Theme?: ThemeConfig;
  Notifications?: {
    Push: {
      AWSPinpoint: AWSPinpointConfig;
    };
  };
  Google?: {
    Analytics: {
      trackingId: string;
      rollupId: string;
    };
  };
}

const config: Record<string, SiteConfig> = {
  "dev.summitdaily.com": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      // OPTIONAL - disable Analytics if true
      disabled: false,
      // OPTIONAL - Allow recording session events. Default is true.
      autoSessionRecord: true,
      AWSPinpoint: {
        appId: "afad7dddd331448e84f9d770625bd94d",
        region: "us-west-2",
        userPoolId: "us-west-2_i3wjFy9vc",
        userPoolWebClientId: "s12edm6otcvdr0le10aojt4bc",
        identityPoolId: "us-west-2:7353b930-6d4b-4d5e-82a0-f99144e9e0cb",
        mandatorySignIn: false,
        // OPTIONAL -  Customized endpoint
        // endpointId: 'XXXXXXXXXXXX',
        // OPTIONAL - client context
        // clientContext: {
        //     clientId: 'xxxxx',
        //     appTitle: 'xxxxx',
        //     appVersionName: 'xxxxx',
        //     appVersionCode: 'xxxxx',
        //     appPackageName: 'xxxxx',
        //     platform: 'xxxxx',
        //     platformVersion: 'xxxxx',
        //     model: 'xxxxx',
        //     make: 'xxxxx',
        //     locale: 'xxxxx'
        // },

        // Buffer settings used for reporting analytics events.
        // OPTIONAL - The buffer size for events in number of items.
        // bufferSize: 1000,

        // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
        // flushInterval: 5000, // 5s

        // OPTIONAL - The number of events to be deleted from the buffer when flushed.
        // flushSize: 100,

        // OPTIONAL - The limit for failed recording retries.
        // resendLimit: 5
      },
    },
    Auth: {
      mandatorySignIn: false,
      userPoolWebClientId: "s12edm6otcvdr0le10aojt4bc",
      region: 'us-west-2',
      signUpVerificationMethod: 'code',
      pubID: "devsdn",
      pubSecondStreetID: "227", // from secondstreet, using SDN live for dev.sdn
      Cognito: {
        userPoolId: 'us-west-2_i3wjFy9vc',
        userPoolClientId: 's12edm6otcvdr0le10aojt4bc',
        identityPoolId: 'us-west-2:7353b930-6d4b-4d5e-82a0-f99144e9e0cb',
        signUpVerificationMethod: 'code',
        loginWith: {
          email: true,
          phone: false,
          username: false,
          oauth: {
            domain: 'devsummitdaily.auth.us-west-2.amazoncognito.com',
            scopes: ['email', 'openid', 'profile'],
            redirectSignIn: [
              'http://localhost:5173',
              'https://dev.summitdaily.com',
              'https://www.summitdaily.com'
            ],
            redirectSignOut: [
              'http://localhost:5173',
              'https://dev.summitdaily.com',
              'https://www.summitdaily.com'
            ],
            responseType: 'code',
            providers: ['Google', 'Facebook']
          }
        }
      }
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/dev/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const { tokens } = await Auth.getCurrentSession();
              const token = tokens.idToken.toString();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "devsdn",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": this.parentConfig?.Auth?.pubID || "devsdn",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "afad7dddd331448e84f9d770625bd94d",
          region: "us-west-2",
          userPoolId: "us-west-2_i3wjFy9vc",
          userPoolWebClientId: "s12edm6otcvdr0le10aojt4bc",
          identityPoolId: "us-west-2:7353b930-6d4b-4d5e-82a0-f99144e9e0cb",
          mandatorySignIn: false,
        },
      },
    },
    Google: {
      Analytics: {
        trackingId: "G-T3VWY8QS9E",
        rollupId: "G-WQGPC28Z69",
      },
    },
  },
  "stage.summitdaily.com": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      disabled: false,
      autoSessionRecord: true,
      AWSPinpoint: {
        appId: "afad7dddd331448e84f9d770625bd94d",
        region: "us-west-2",
        userPoolId: "us-west-2_i3wjFy9vc",
        userPoolWebClientId: "s12edm6otcvdr0le10aojt4bc",
        identityPoolId: "us-west-2:7353b930-6d4b-4d5e-82a0-f99144e9e0cb",
        mandatorySignIn: false,
      },
    },
    Auth: {
      mandatorySignIn: false,
      region: "us-west-2",
      userPoolId: "us-west-2_i3wjFy9vc",
      userPoolWebClientId: "s12edm6otcvdr0le10aojt4bc",
      identityPoolId: "us-west-2:7353b930-6d4b-4d5e-82a0-f99144e9e0cb",
      pubID: "stagesdn",
      pubSecondStreetID: "227", // from secondstreet, using SDN live for dev.sdn
      oauth: {
        domain: "devsummitdaily.auth.us-west-2.amazoncognito.com",
        scopes: ['email', 'openid', 'profile'],
        redirectSignIn: "https://stage.summitdaily.com",
        redirectSignOut: "https://stage.summitdaily.com",
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/stage/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const { tokens } = await Auth.getCurrentSession();
              const token = tokens.idToken.toString();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "stagesdn",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": "devsdn",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "afad7dddd331448e84f9d770625bd94d",
          region: "us-west-2",
          userPoolId: "us-west-2_i3wjFy9vc",
          userPoolWebClientId: "s12edm6otcvdr0le10aojt4bc",
          identityPoolId: "us-west-2:7353b930-6d4b-4d5e-82a0-f99144e9e0cb",
          mandatorySignIn: false,
        },
      },
    },
    Google: {
      Analytics: {
        trackingId: "G-T3VWY8QS9E",
        rollupId: "G-WQGPC28Z69",
      },
    },
  },
  "summitdaily.local": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      disabled: false,
      autoSessionRecord: true,
      AWSPinpoint: {
        appId: "afad7dddd331448e84f9d770625bd94d",
        region: "us-west-2",
        userPoolId: "us-west-2_i3wjFy9vc",
        userPoolWebClientId: "s12edm6otcvdr0le10aojt4bc",
        identityPoolId: "us-west-2:7353b930-6d4b-4d5e-82a0-f99144e9e0cb",
        mandatorySignIn: false,
      },
    },
    Auth: {
      identityPoolId: "us-west-2:7353b930-6d4b-4d5e-82a0-f99144e9e0cb",
      region: "us-west-2",
      userPoolId: "us-west-2_i3wjFy9vc",
      clientID: "s12edm6otcvdr0le10aojt4bc",
      userPoolWebClientId: "s12edm6otcvdr0le10aojt4bc",
      mandatorySignIn: false,
      signUpVerificationMethod: "code", // 'code' | 'link'
      pubID: "devsdn",
      pubSecondStreetID: "227", // from secondstreet, using SDN live for dev.sdn
      oauth: {
        domain: "devsummitdaily.auth.us-west-2.amazoncognito.com",
        scopes: ['email', 'openid', 'profile'],
        redirectSignIn: "https://summitdaily.local",
        redirectSignOut: "https://summitdaily.local",
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/dev/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "devsdn",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": "devsdn",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "afad7dddd331448e84f9d770625bd94d",
          region: "us-west-2",
          userPoolId: "us-west-2_i3wjFy9vc",
          userPoolWebClientId: "s12edm6otcvdr0le10aojt4bc",
          identityPoolId: "us-west-2:7353b930-6d4b-4d5e-82a0-f99144e9e0cb",
          mandatorySignIn: false,
        },
      }
    },
    Google: {
      Analytics: {
        trackingId: "G-T3VWY8QS9E",
        rollupId: "G-WQGPC28Z69",
      },
    },
  },
  "dev.aspentimes.com": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      AWSPinpoint: {
        appId: "e539d593b2b345f1b221ea49962fd057",
        region: "us-west-2",
        userPoolId: "us-west-2_Cyoi5gJzk",
        userPoolWebClientId: "1uae0pleac6j5hcr5ikadfhfhb",
        identityPoolId: "us-west-2:d37cb242-7072-4355-ac23-7124a4c91803",
        mandatorySignIn: false,
      },
    },
    Auth: {
      mandatorySignIn: false,
      region: "us-west-2",
      userPoolId: "us-west-2_Cyoi5gJzk",
      userPoolWebClientId: "1uae0pleac6j5hcr5ikadfhfhb",
      identityPoolId: "us-west-2:d37cb242-7072-4355-ac23-7124a4c91803",
      signUpVerificationMethod: "code", // 'code' | 'link'
      pubID: "devatd",
      pubSecondStreetID: "435",
      oauth: {
        domain: "devaspentimes.auth.us-west-2.amazoncognito.com",
        scopes: ['email', 'openid', 'profile'],
        redirectSignIn: "https://dev.aspentimes.com",
        redirectSignOut: "https://dev.aspentimes.com",
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/dev/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "devatd",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": "devatd",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "e539d593b2b345f1b221ea49962fd057",
          region: "us-west-2",
          userPoolId: "us-west-2_Cyoi5gJzk",
          userPoolWebClientId: "1uae0pleac6j5hcr5ikadfhfhb",
          identityPoolId: "us-west-2:d37cb242-7072-4355-ac23-7124a4c91803",
          mandatorySignIn: false,
        },
      },
    },
  },
  "stage.aspentimes.com": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      AWSPinpoint: {
        appId: "e539d593b2b345f1b221ea49962fd057",
        region: "us-west-2",
        userPoolId: "us-west-2_Cyoi5gJzk",
        userPoolWebClientId: "1uae0pleac6j5hcr5ikadfhfhb",
        identityPoolId: "us-west-2:d37cb242-7072-4355-ac23-7124a4c91803",
        mandatorySignIn: false,
      },
    },
    Auth: {
      identityPoolId: "us-west-2:0a0abc03-b175-4c95-b363-7220fabdedf9",
      region: "us-west-2",
      userPoolId: "us-west-2_rAKc7GBCi",
      clientID: "hn15278v9fvkn8rlj8ustroto",
      userPoolWebClientId: "hn15278v9fvkn8rlj8ustroto",
      mandatorySignIn: false,
      signUpVerificationMethod: "code", // 'code' | 'link'
      pubID: "atd",
      pubSecondStreetID: "1856",
      oauth: {
        domain: "login.aspentimes.com",
        scopes: ['email', 'openid', 'profile'],
        redirectSignIn: "https://stage.aspentimes.com",
        redirectSignOut: "https://stage.aspentimes.com",
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/stage/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "stageatd",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": "stageatd",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "e539d593b2b345f1b221ea49962fd057",
          region: "us-west-2",
          userPoolId: "us-west-2_Cyoi5gJzk",
          userPoolWebClientId: "1uae0pleac6j5hcr5ikadfhfhb",
          identityPoolId: "us-west-2:d37cb242-7072-4355-ac23-7124a4c91803",
          mandatorySignIn: false,
        },
      },
    },
  },
  "steamboatpilot.local": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      AWSPinpoint: {
        appId: "afad7dddd331448e84f9d770625bd94d",
        region: "us-west-2",
        userPoolId: "us-west-2_05Jb7NrPC",
        userPoolWebClientId: "s12edm6otcvdr0le10aojt4bc",
        identityPoolId: "us-west-2:7b42b11d-775c-4209-b22d-5475f48e609f",
        mandatorySignIn: false,
      },
    },
    Auth: {
      identityPoolId: "us-west-2:7b42b11d-775c-4209-b22d-5475f48e609f",
      region: "us-west-2",
      userPoolId: "us-west-2_05Jb7NrPC",
      clientID: "2f3t4s4lgppdc6oo9tfmcjq17q",
      userPoolWebClientId: "s12edm6otcvdr0le10aojt4bc",
      mandatorySignIn: false,
      signUpVerificationMethod: "code", // 'code' | 'link'
      pubID: "devsbt",
      pubSecondStreetID: "813",
      oauth: {
        domain: "devsteamboatpilot.auth.us-west-2.amazoncognito.com",
        scopes: ['email', 'openid', 'profile'],
        redirectSignIn: "https://steamboatpilot.local",
        redirectSignOut: "https://steamboatpilot.local",
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/dev/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "devsbt",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": "devsbt",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "afad7dddd331448e84f9d770625bd94d",
          region: "us-west-2",
          userPoolId: "us-west-2_05Jb7NrPC",
          userPoolWebClientId: "s12edm6otcvdr0le10aojt4bc",
          identityPoolId: "us-west-2:7b42b11d-775c-4209-b22d-5475f48e609f",
          mandatorySignIn: false,
        },
      },
    },
    Google: {
      Analytics: {
        trackingId: "G-Y7BFXRCL9L",
        rollupId: "G-WQGPC28Z69",
      },
    },
  },
  "dev.steamboatpilot.com": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      AWSPinpoint: {
        appId: "afad7dddd331448e84f9d770625bd94d",
        region: "us-west-2",
        userPoolId: "us-west-2_i3wjFy9vc",
        userPoolWebClientId: "s12edm6otcvdr0le10aojt4bc",
        identityPoolId: "us-west-2:7b42b11d-775c-4209-b22d-5475f48e609f",
        mandatorySignIn: false,
      },
    },
    Auth: {
      region: "us-west-2",
      userPoolId: "us-west-2_05Jb7NrPC",
      clientID: "2f3t4s4lgppdc6oo9tfmcjq17q",
      userPoolWebClientId: "2f3t4s4lgppdc6oo9tfmcjq17q",
      identityPoolId: "us-west-2:7b42b11d-775c-4209-b22d-5475f48e609f",
      mandatorySignIn: false,
      signUpVerificationMethod: "code", // 'code' | 'link'
      pubID: "devsbt",
      pubSecondStreetID: "813",
      oauth: {
        domain: "https://devsteamboatpilot.auth.us-west-2.amazoncognito.com",
        scopes: ['email', 'openid', 'profile'],
        redirectSignIn: "https://dev.steamboatpilot.com",
        redirectSignOut: "https://dev.steamboatpilot.com",
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/dev/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "devsbt",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": "devsbt",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "afad7dddd331448e84f9d770625bd94d",
          region: "us-west-2",
          userPoolId: "us-west-2_05Jb7NrPC",
          userPoolWebClientId: "2f3t4s4lgppdc6oo9tfmcjq17q",
          identityPoolId: "us-west-2:7b42b11d-775c-4209-b22d-5475f48e609f",
          mandatorySignIn: false,
        },
      },
    },
    Google: {
      Analytics: {
        trackingId: "G-Y7BFXRCL9L",
        rollupId: "G-WQGPC28Z69",
      },
    },
  },
  "www.summitdaily.com": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      AWSPinpoint: {
        appId: "8ffae20f8d4a4e0592fc90dad58b05c3",
        identityPoolId: "us-west-2:00a80385-30b1-4c2a-96bf-653a5898fd5a",
        region: "us-west-2",
        userPoolId: "us-west-2_RHDYAqeFp",
        userPoolWebClientId: "1litubhdrin8vmt96rbfuolmbr",
        mandatorySignIn: false,
      },
    },
    Auth: {
      identityPoolId: "us-west-2:00a80385-30b1-4c2a-96bf-653a5898fd5a",
      region: "us-west-2",
      userPoolId: "us-west-2_RHDYAqeFp",
      clientID: "1litubhdrin8vmt96rbfuolmbr",
      userPoolWebClientId: "1litubhdrin8vmt96rbfuolmbr",
      mandatorySignIn: false,
      signUpVerificationMethod: "code", // 'code' | 'link'
      pubID: "sdn",
      pubSecondStreetID: "227",
      oauth: {
        domain: "login.summitdaily.com",
        scopes: ['email', 'openid', 'profile'],
        redirectSignIn: "https://www.summitdaily.com",
        redirectSignOut: "https://www.summitdaily.com",
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/prod/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "sdn",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": "sdn",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "8ffae20f8d4a4e0592fc90dad58b05c3",
          identityPoolId: "us-west-2:00a80385-30b1-4c2a-96bf-653a5898fd5a",
          region: "us-west-2",
          userPoolId: "us-west-2_RHDYAqeFp",
          userPoolWebClientId: "1litubhdrin8vmt96rbfuolmbr",
          mandatorySignIn: false,
        },
      },
    },
    Google: {
      Analytics: {
        trackingId: "G-T3VWY8QS9E",
        rollupId: "G-WQGPC28Z69",
      },
    },
  },
  "www.steamboatpilot.com": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      AWSPinpoint: {
        appId: "c0667d2e00e5430ebabd2c38cb3b4f98",
        identityPoolId: "us-west-2:4d3a14ce-5d6b-4506-85a9-6ecbc584842a",
        region: "us-west-2",
        userPoolId: "us-west-2_dIO5907LE",
        userPoolWebClientId: "7jcs67v3mfgrgctlv4p5rvn2k7",
        mandatorySignIn: false,
      },
    },
    Auth: {
      identityPoolId: "us-west-2:4d3a14ce-5d6b-4506-85a9-6ecbc584842a",
      region: "us-west-2",
      userPoolId: "us-west-2_dIO5907LE",
      clientID: "7jcs67v3mfgrgctlv4p5rvn2k7",
      userPoolWebClientId: "7jcs67v3mfgrgctlv4p5rvn2k7",
      mandatorySignIn: false,
      signUpVerificationMethod: "code", // 'code' | 'link'
      pubID: "sbt",
      pubSecondStreetID: "813",
      oauth: {
        domain: "login.steamboatpilot.com",
        scopes: ['email', 'openid', 'profile'],
        redirectSignIn: "https://www.steamboatpilot.com",
        redirectSignOut: "https://www.steamboatpilot.com",
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/prod/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "sbt",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": "sbt",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "c0667d2e00e5430ebabd2c38cb3b4f98",
          identityPoolId: "us-west-2:4d3a14ce-5d6b-4506-85a9-6ecbc584842a",
          region: "us-west-2",
          userPoolId: "us-west-2_dIO5907LE",
          userPoolWebClientId: "7jcs67v3mfgrgctlv4p5rvn2k7",
          mandatorySignIn: false,
        },
      },
    },
    Google: {
      Analytics: {
        trackingId: "G-Y7BFXRCL9L",
        rollupId: "G-WQGPC28Z69",
      },
    },
  },
  "www.aspentimes.com": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      AWSPinpoint: {
        appId: "721e70d9a11048a988f5bab093158787",
        region: "us-west-2",
        userPoolId: "us-west-2_rAKc7GBCi",
        userPoolWebClientId: "hn15278v9fvkn8rlj8ustroto",
        identityPoolId: "us-west-2:0a0abc03-b175-4c95-b363-7220fabdedf9",
        mandatorySignIn: false,
      },
    },
    Auth: {
      identityPoolId: "us-west-2:0a0abc03-b175-4c95-b363-7220fabdedf9",
      region: "us-west-2",
      userPoolId: "us-west-2_rAKc7GBCi",
      clientID: "hn15278v9fvkn8rlj8ustroto",
      userPoolWebClientId: "hn15278v9fvkn8rlj8ustroto",
      mandatorySignIn: false,
      signUpVerificationMethod: "code", // 'code' | 'link'
      pubID: "atd",
      pubSecondStreetID: "435",
      oauth: {
        domain: "login.aspentimes.com",
        scopes: ['email', 'openid', 'profile'],
        redirectSignIn: "https://www.aspentimes.com",
        redirectSignOut: "https://www.aspentimes.com",
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/prod/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "atd",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": "atd",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "721e70d9a11048a988f5bab093158787",
          region: "us-west-2",
          userPoolId: "us-west-2_rAKc7GBCi",
          userPoolWebClientId: "hn15278v9fvkn8rlj8ustroto",
          identityPoolId: "us-west-2:0a0abc03-b175-4c95-b363-7220fabdedf9",
          mandatorySignIn: false,
        },
      },
    },
    Google: {
      Analytics: {
        trackingId: "G-KYRD5430Y9",
        rollupId: "G-WQGPC28Z69",
      },
    },
  },
  "www.skyhinews.com": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      AWSPinpoint: {
        appId: "74c795100a5d49a59185ab169317df20",
        region: "us-west-2",
        userPoolId: "us-west-2_Xhd3OXoJF",
        userPoolWebClientId: "3ag5ldpsa4ltotsf824ftlurg8",
        identityPoolId: "us-west-2:5e895562-6e4a-4e89-9a7e-56c1d9e2cae7",
        mandatorySignIn: false,
      },
    },
    Auth: {
      identityPoolId: "us-west-2:5e895562-6e4a-4e89-9a7e-56c1d9e2cae7",
      region: "us-west-2",
      userPoolId: "us-west-2_Xhd3OXoJF",
      clientID: "3ag5ldpsa4ltotsf824ftlurg8",
      userPoolWebClientId: "3ag5ldpsa4ltotsf824ftlurg8",
      mandatorySignIn: false,
      signUpVerificationMethod: "code", // 'code' | 'link'
      pubID: "shn",
      pubSecondStreetID: "1856",
      oauth: {
        domain: "login.skyhinews.com",
        scopes: ['email', 'openid', 'profile'],
        redirectSignIn: "https://www.skyhinews.com",
        redirectSignOut: "https://www.skyhinews.com",
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/prod/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "shn",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": "shn",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "74c795100a5d49a59185ab169317df20",
          region: "us-west-2",
          userPoolId: "us-west-2_Xhd3OXoJF",
          userPoolWebClientId: "3ag5ldpsa4ltotsf824ftlurg8",
          identityPoolId: "us-west-2:5e895562-6e4a-4e89-9a7e-56c1d9e2cae7",
          mandatorySignIn: false,
        },
      },
    },
    Google: {
      Analytics: {
        trackingId: "G-VY7RXGLN0T",
        rollupId: "G-WQGPC28Z69",
      },
    },
  },
  "www.craigdailypress.com": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      AWSPinpoint: {
        appId: "4517e47881b5453381a1587bfe5127f7",
        region: "us-west-2",
        userPoolId: "us-west-2_FJGN4bSMn",
        userPoolWebClientId: "7rt98quics3e78vsh6btf1c0u0",
        identityPoolId: "us-west-2:9236ac4e-62b4-4fc3-a019-8cdfd9b39b84",
        mandatorySignIn: false,
      },
    },
    Auth: {
      identityPoolId: "us-west-2:9236ac4e-62b4-4fc3-a019-8cdfd9b39b84",
      region: "us-west-2",
      userPoolId: "us-west-2_FJGN4bSMn",
      clientID: "7rt98quics3e78vsh6btf1c0u0",
      userPoolWebClientId: "7rt98quics3e78vsh6btf1c0u0",
      mandatorySignIn: false,
      signUpVerificationMethod: "code", // 'code' | 'link'
      pubID: "cdp",
      pubSecondStreetID: "669",
      oauth: {
        domain: "login.craigdailypress.com",
        scopes: ['email', 'openid', 'profile'],
        redirectSignIn: "https://www.craigdailypress.com",
        redirectSignOut: "https://www.craigdailypress.com",
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/prod/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "cdp",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": "cdp",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "4517e47881b5453381a1587bfe5127f7",
          region: "us-west-2",
          userPoolId: "us-west-2_FJGN4bSMn",
          userPoolWebClientId: "7rt98quics3e78vsh6btf1c0u0",
          identityPoolId: "us-west-2:9236ac4e-62b4-4fc3-a019-8cdfd9b39b84",
          mandatorySignIn: false,
        },
      },
    },
    Google: {
      Analytics: {
        trackingId: "G-3YQKBF71GK",
        rollupId: "G-WQGPC28Z69",
      },
    },
  },
  "www.vaildaily.com": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      AWSPinpoint: {
        appId: "8992571538da4cd7ad1f2dbbc910d432",
        region: "us-west-2",
        userPoolId: "us-west-2_tjJ5TCYep",
        userPoolWebClientId: "5m38ck5hep21ron5dv98ce0ife",
        identityPoolId: "us-west-2:397540c2-31f3-4b5e-97a8-c45a48b8087f",
        mandatorySignIn: false,
      },
    },
    Auth: {
      identityPoolId: "us-west-2:397540c2-31f3-4b5e-97a8-c45a48b8087f",
      region: "us-west-2",
      userPoolId: "us-west-2_tjJ5TCYep",
      clientID: "5m38ck5hep21ron5dv98ce0ife",
      userPoolWebClientId: "5m38ck5hep21ron5dv98ce0ife",
      mandatorySignIn: false,
      signUpVerificationMethod: "code", // 'code' | 'link'
      pubID: "vdn",
      pubSecondStreetID: "245",
      oauth: {
        domain: "login.vaildaily.com",
        scopes: ['email', 'openid', 'profile'],
        redirectSignIn: "https://www.vaildaily.com",
        redirectSignOut: "https://www.vaildaily.com",
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/prod/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "vdn",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": "vdn",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "8992571538da4cd7ad1f2dbbc910d432",
          region: "us-west-2",
          userPoolId: "us-west-2_tjJ5TCYep",
          userPoolWebClientId: "5m38ck5hep21ron5dv98ce0ife",
          identityPoolId: "us-west-2:397540c2-31f3-4b5e-97a8-c45a48b8087f",
          mandatorySignIn: false,
        },
      },
    },
    Google: {
      Analytics: {
        trackingId: "G-DJKK3S8WYC",
        rollupId: "G-WQGPC28Z69",
      },
    },
  },
  "www.postindependent.com": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      AWSPinpoint: {
        appId: "c2b6dc11db9e40c4904da3738b1f7876",
        region: "us-west-2",
        userPoolId: "us-west-2_X7sE9syP2",
        userPoolWebClientId: "58ujl7e556ujn67frn88jhghs1",
        identityPoolId: "us-west-2:da7a75eb-1281-4e82-a175-710e1a54662d",
        mandatorySignIn: false,
      },
    },
    Auth: {
      identityPoolId: "us-west-2:da7a75eb-1281-4e82-a175-710e1a54662d",
      region: "us-west-2",
      userPoolId: "us-west-2_X7sE9syP2",
      clientID: "58ujl7e556ujn67frn88jhghs1",
      userPoolWebClientId: "58ujl7e556ujn67frn88jhghs1",
      mandatorySignIn: false,
      signUpVerificationMethod: "code", // 'code' | 'link'
      pubID: "gsp",
      pubSecondStreetID: "891",
      oauth: {
        domain: "login.postindependent.com",
        scopes: ['email', 'openid', 'profile'],
        redirectSignIn: "https://www.postindependent.com",
        redirectSignOut: "https://www.postindependent.com",
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/prod/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "gspi",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": "gspi",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "c2b6dc11db9e40c4904da3738b1f7876",
          region: "us-west-2",
          userPoolId: "us-west-2_X7sE9syP2",
          userPoolWebClientId: "58ujl7e556ujn67frn88jhghs1",
          identityPoolId: "us-west-2:da7a75eb-1281-4e82-a175-710e1a54662d",
          mandatorySignIn: false,
        },
      },
    },
    Google: {
      Analytics: {
        trackingId: "G-4TY8LGZ2DB",
        rollupId: "G-WQGPC28Z69",
      },
    },
  },
  "www.tahoedailytribune.com": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      AWSPinpoint: {
        appId: "c8554a4df3c64f45ace359958c7ef641",
        identityPoolId: "us-west-2:997d347d-cadd-449f-832a-ca722afdcd05",
        region: "us-west-2",
        userPoolId: "us-west-2_CAkChvTkO",
        userPoolWebClientId: "4c89r93a8rqe50r310s953grvb",
        mandatorySignIn: false,
      },
    },
    Auth: {
      identityPoolId: "us-west-2:997d347d-cadd-449f-832a-ca722afdcd05",
      region: "us-west-2",
      userPoolId: "us-west-2_CAkChvTkO",
      clientID: "4c89r93a8rqe50r310s953grvb",
      userPoolWebClientId: "4c89r93a8rqe50r310s953grvb",
      mandatorySignIn: false,
      signUpVerificationMethod: "code", // 'code' | 'link'
      pubID: "tdt",
      pubSecondStreetID: "1963",
      oauth: {
        domain: "login.tahoedailytribune.com",
        scopes: ['email', 'openid', 'profile'],
        redirectSignIn: "https://www.tahoedailytribune.com",
        redirectSignOut: "https://www.tahoedailytribune.com",
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/prod/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "tdt",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": "tdt",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "c8554a4df3c64f45ace359958c7ef641",
          identityPoolId: "us-west-2:997d347d-cadd-449f-832a-ca722afdcd05",
          region: "us-west-2",
          userPoolId: "us-west-2_CAkChvTkO",
          userPoolWebClientId: "4c89r93a8rqe50r310s953grvb",
          mandatorySignIn: false,
        },
      },
    },
    Google: {
      Analytics: {
        trackingId: "G-728DWKFLF3",
        rollupId: "G-WQGPC28Z69",
      },
    },
  },
  "www.sierrasun.com": {
    Theme: {
      colors: {
        brand: {
          primary: {
            "10": "#037bc1", // Use CSS variable
            "80": "#000000",
            "100": "#cccccc",
          },
          secondary: {
            // '10': 'var(--secondary-color)', // Use CSS variable
            "10": "#ffcccc",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
    },
    Analytics: {
      AWSPinpoint: {
        appId: "40021cd03c964ca693d797b6047ea0eb",
        identityPoolId: "us-west-2:8c91c9a1-4dcd-4719-b9e8-af3738df7a93",
        region: "us-west-2",
        userPoolId: "us-west-2_oIJStB045",
        userPoolWebClientId: "43if4d3p2nsq1te9mvqbg3hplg",
        mandatorySignIn: false,
      },
    },
    Auth: {
      identityPoolId: "us-west-2:8c91c9a1-4dcd-4719-b9e8-af3738df7a93",
      region: "us-west-2",
      userPoolId: "us-west-2_oIJStB045",
      clientID: "43if4d3p2nsq1te9mvqbg3hplg",
      userPoolWebClientId: "43if4d3p2nsq1te9mvqbg3hplg",
      mandatorySignIn: false,
      signUpVerificationMethod: "code", // 'code' | 'link'
      pubID: "ssu",
      pubSecondStreetID: "1912",
      oauth: {
        domain: "login.sierrasun.com",
        scopes: ['email', 'openid', 'profile'],
        redirectSignIn: "https://www.sierrasun.com",
        redirectSignOut: "https://www.sierrasun.com",
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "swift-website-samapp",
          endpoint: "https://y5u5h9dzjk.execute-api.us-west-2.amazonaws.com/prod/",
          region: "us-west-2",
          authorizationType: "AMAZON_COGNITO_USER_POOLS",
          custom_header: async (): Promise<Record<string, string>> => {
            try {
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              return {
                Authorization: `Bearer ${token}`,
                "x-pubid": "ssu",
              };
            } catch (error) {
              console.error("Error fetching token:", error);
              return {
                Authorization: "",
                "x-pubid": "ssu",
              };
            }
          },
        },
      ],
    },
    Notifications: {
      Push: {
        AWSPinpoint: {
          appId: "40021cd03c964ca693d797b6047ea0eb",
          identityPoolId: "us-west-2:8c91c9a1-4dcd-4719-b9e8-af3738df7a93",
          region: "us-west-2",
          userPoolId: "us-west-2_oIJStB045",
          userPoolWebClientId: "43if4d3p2nsq1te9mvqbg3hplg",
          mandatorySignIn: false,
        },
      },
    },
    Google: {
      Analytics: {
        trackingId: "G-WV7ZP81RP4",
        rollupId: "G-WQGPC28Z69",
      },
    },
  },
};
export default config;
