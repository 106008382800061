import React, { useEffect, useState } from 'react';
import { signOut, getCurrentUser } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';

const LogoutButton: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const user = await getCurrentUser();
        setIsLoggedIn(!!user);
      } catch {
        setIsLoggedIn(false);
      }
    };

    checkUser();

    const listener = (data: { payload: { event: string } }) => {
      switch (data.payload.event) {
        case 'signIn':
          setIsLoggedIn(true);
          break;
        case 'signOut':
          setIsLoggedIn(false);
          break;
        default:
          break;
      }
    };

    const hubListenerCancelToken = Hub.listen('auth', listener);
    

    return () => {
      hubListenerCancelToken(); // remove listener
    };
  }, []);

  const handleLogout = async () => {
    try {
      await signOut();
      console.log('User signed out');
      window.location.href = '/';
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return isLoggedIn ? (
    <span
      onClick={handleLogout}
      className="tw-bg-transparent tw-text-black tw-px-4 tw-py-2 tw-rounded hover:tw-bg-primary"
      style={{ cursor: 'pointer' }}
    >
      Sign Out
    </span>
  ) : (
    <span
      onClick={() => (window.location.href = '/authentication')}
      className="tw-bg-transparent tw-text-black tw-px-4 tw-py-2 tw-rounded hover:tw-bg-primary"
      style={{ cursor: 'pointer' }}
    >
      Sign In
    </span>
  );
};

export default LogoutButton;