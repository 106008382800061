import React, { useState, useEffect } from "react";
import { X, Mail, Facebook } from "lucide-react";
import { getSessionCount, fetchRegistrationWalls, setSessionCount } from "../utils/sessionUtils";
import { getCurrentUser } from "@aws-amplify/auth";
import ReactGA from "react-ga4";
import { signInWithGoogle, signInWithFacebook } from '../utils/cognito';

const handleEmailSignIn = async (chosenProvider: string) => {
    // Save the current URL to return to after sign in
    const currentUrl = window.location.href;
    sessionStorage.setItem('bookmarkReturnUrl', currentUrl);
    
    // if chosenProvider is 'email', redirect to the authentication page
    if (chosenProvider === 'email') {
      window.location.href = "/authentication/";
    }
    return false
};

const handleSocialSignIn = async (chosenProvider: string) => {
    // Save the current URL to return to after sign in
    const currentUrl = window.location.href;
    sessionStorage.setItem('bookmarkReturnUrl', currentUrl);
    
    // if chosenProvider is 'google' or 'facebook', sign in with that provider
    if (chosenProvider === 'google') {
      await signInWithGoogle();
    } else if (chosenProvider === 'facebook') {
      await signInWithFacebook();
    }
    return false;
  };

const PaywallModal: React.FC = () => {
  interface RegistrationWall {
    threshold: number;
    title: string;
    message: string;
    active: boolean;
    dismissable: boolean;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [wallContent, setWallContent] = useState<RegistrationWall | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [isDismissable, setIsDismissable] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        await getCurrentUser();
        setIsAuthenticated(true);
      } catch {
        setIsAuthenticated(false);
      }
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    if (isAuthenticated) return;

    const checkSessionAndShowModal = async () => {
      try {
        const sessionCount = getSessionCount();
        const regWalls = await fetchRegistrationWalls();

        function getActiveWall(sessionCount: number): RegistrationWall | null {
          const activeWalls = regWalls?.filter((wall) => wall.active).sort((a, b) => a.threshold - b.threshold);

          let wallToShow = null;
          if (activeWalls) {
            for (const wall of activeWalls) {
              if (sessionCount >= wall.threshold) {
                wallToShow = wall;
              } else {
                break;
              }
            }
          }
          return wallToShow;
        }

        const activeWall = getActiveWall(sessionCount);

        if (activeWall) {
          setWallContent({ ...activeWall });
          setIsDismissable(activeWall.dismissable);
          setIsOpen(true);

          // TODO
          // if (window.location.hostname.includes('dev.') || window.location.hostname.includes('local')) {
          //   gtag('set', 'user_properties', {
          //       dev_testdimension: 'Dev site signed out'
          //   });
          // }
          // // send the sub as the userID to GA4
          // gtag('config', tagID, {
          //   'user_id': sub
          // });
          ReactGA.event({
            category: "devtest",
            action: "show_registration_wall",
            label: "which_name", // optional
            // value: 99, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });


        }
      } catch (error) {
        console.error("Error fetching registration wall: ", error);
      }
    };

    checkSessionAndShowModal();

    const handleKeyDown = async (event: KeyboardEvent) => {
      const regWalls = await fetchRegistrationWalls();

      if (event.metaKey && event.ctrlKey && ["0", "1", "2", "3"].includes(event.key)) {
        if (event.key === "0") {
          setSessionCount(0);
          window.location.reload();
        } else {
          const selectedWall = regWalls ? regWalls[Number(event.key) - 1] : undefined;
          if (selectedWall) {
            setSessionCount(selectedWall.threshold);
            setWallContent(selectedWall);
            setIsDismissable(selectedWall.dismissable);
            window.location.reload();
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown.bind(null));

    return () => {
      window.removeEventListener("keydown", handleKeyDown.bind(null));
    };
  }, [isAuthenticated, isDismissable]);

  useEffect(() => {
    if (isOpen && !isDismissable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen, isDismissable]);

  const closeModal = () => setIsOpen(false);

  if (!isOpen || !wallContent || isAuthenticated) return null;

  return (
    <div
      className="tw-fixed tw-backdrop tw-inset-0 tw-bg-black tw-bg-opacity-85 backdrop-opacity-85 tw-flex tw-items-center tw-justify-center tw-z-50 tw-pb-16"
      onClick={(e) => {
        if (isDismissable && (e.target as HTMLElement).classList.contains("tw-backdrop")) {
          closeModal();
        }
      }}
    >
      <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-max-w-md tw-w-full tw-relative tw-mb-8">
        {isDismissable && (
          <button onClick={closeModal} className="tw-absolute tw-right-4 tw-top-4 tw-bg-transparent">
            <X className="tw-bg-transparent" />
          </button>
        )}
        <div className="tw-text-pretty tw-text-center" dangerouslySetInnerHTML={{ __html: wallContent.message }}></div>
        {/* <button
          onClick={() => (window.location.href = "/authentication")}
          className="tw-w-fit tw-mx-auto tw-bg-blue-500 hover:tw-bg-blue-600 tw-text-white tw-font-medium tw-py-1.5 tw-px-3 tw-rounded tw-mt-3 tw-text-sm tw-flex tw-items-center tw-justify-center tw-gap-2"
        >
          <Mail className="tw-w-4 tw-h-4" />
          Sign in or Sign Up
        </button> */}

        <div className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-mt-3">
        {/* <span className="tw-text-sm tw-text-gray-500">or continue with</span> */}
        <div className="tw-flex tw-gap-3 tw-justify-center">
        <button
            type="button"
            onClick={() => handleEmailSignIn('email')}
            className="tw-w-full tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-bg-blue-500 hover:tw-bg-blue-600 tw-text-sm tw-font-medium tw-text-white"
          >
            <span className="tw-sr-only">Sign in with Email</span>
            <Mail className="tw-w-5 tw-h-5 tw-pr-1" />
            <span>Email</span>
          </button>

          <button
            type="button"
            onClick={() => handleSocialSignIn('google')}
            className="tw-w-full tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-bg-blue-500 hover:tw-bg-blue-600 tw-text-sm tw-font-medium tw-text-white"
          >
            <span className="tw-sr-only">Sign in with Google</span>
            <svg className="tw-w-5 tw-h-5" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"
              />
            </svg>
          </button>

          <button
            type="button"
            onClick={() => handleSocialSignIn('facebook')}
            className="tw-w-full tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-bg-blue-500 hover:tw-bg-blue-600 tw-text-sm tw-font-medium tw-text-white"
          >
            <span className="tw-sr-only">Sign in with Facebook</span>
            <Facebook className="tw-w-5 tw-h-5" />
          </button>

        </div>
      </div>

    </div>
  </div>

  );
};

export default PaywallModal;
