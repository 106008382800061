import React from 'react';
import { Mail, Eye, EyeOff, Loader2, Facebook } from 'lucide-react';
import { AuthFormProps } from '../types';
import { signInWithGoogle, signInWithFacebook } from '../utils/cognito';

// Define error interfaces
interface ValidationError extends Error {
  validation?: string;
  code?: string;
  path?: string[];
}

export const SignInForm: React.FC<AuthFormProps> = ({
  email,
  setEmail,
  password,
  setPassword,
  loading,
  // error,
  // rememberMe,
  // setRememberMe,
  onSubmit,
  setAuthState,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [emailError, setEmailError] = React.useState<string>('');  
  
  // const validateEmail = (email: string): boolean => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailRegex.test(email)) {
  //     setEmailError('Invalid email address');
  //     return false;
  //   }
  //   setEmailError('');
  //   return true;
  // };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value.toLowerCase();
    setEmail(newEmail);
  };

  const handleSocialSignIn = async (provider: 'google' | 'facebook') => {
    try {
      switch (provider) {
        case 'google':
          await signInWithGoogle();
          break;
        case 'facebook':
          await signInWithFacebook();
          break;
      }
    } catch (error: unknown) {
      console.error('Social sign-in error:', error);
      if ((error as ValidationError).validation) {
        console.error('Validation error:', (error as ValidationError).message);
      } else {
        console.error('Unexpected error:', error);
      }
    }
  };

  return (
    <form onSubmit={onSubmit} className="tw-space-y-4">
      <div>
        <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Email</label>
        <div className="tw-mt-1 tw-relative">
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            className={`tw-appearance-none tw-block tw-w-full tw-px-3 tw-py-2 tw-border ${
              emailError ? 'tw-border-red-500' : 'tw-border-gray-300'
            } tw-rounded-md tw-shadow-sm tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-primary-500 focus:tw-border-primary-500`}
            required
          />
          <Mail className="tw-absolute tw-right-3 tw-top-2.5 tw-h-5 tw-w-5 tw-text-gray-400" />
        </div>
        {emailError && (
          <p className="tw-mt-2 tw-text-sm tw-text-red-600">{emailError}</p>
        )}
      </div>

      <div>
        <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">Password</label>
        <div className="tw-mt-1 tw-relative">
          <input
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="tw-appearance-none tw-block tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-placeholder-gray-400 focus:tw-outline-none focus:tw-ring-primary-500 focus:tw-border-primary-500"
        required
          />
          <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="tw-absolute tw-right-3 tw-top-2.5 tw-text-gray-400 hover:tw-text-gray-600"
          >
        {showPassword ? (
          <Eye className="tw-h-5 tw-w-5" />
        ) : (
          <EyeOff className="tw-h-5 tw-w-5" />
        )}
          </button>
        </div>
      </div>

      <div className="tw-flex tw-items-center tw-justify-between">
        {/* <div className="tw-flex tw-items-center">
          <input
            id="remember-me"
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe?.(e.target.checked)}
            className="tw-h-4 tw-w-4 tw-text-primary-600 focus:tw-ring-primary-500 tw-border-gray-300 tw-rounded"
          />
          <label htmlFor="remember-me" className="tw-ml-2 tw-block tw-text-sm tw-text-gray-900">
            Remember me
          </label>
        </div> */}

        <button
          type="button"
          onClick={() => setAuthState('forgotPassword')}
          className="tw-text-sm tw-font-medium tw-text-primary-600 hover:tw-text-primary-500 tw-border tw-border-transparent tw-rounded-md  tw-bg-transparent">
          Forgot password?
        </button>
      </div>

      <div>
        <button
          type="submit"
          disabled={loading}
          className="tw-w-full tw-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-primary-600 hover:tw-bg-primary-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-primary-500"
        >
          {loading ? <Loader2 className="tw-h-5 tw-w-5 tw-animate-spin" /> : 'Sign in'}
        </button>
      </div>

      <div className="tw-mt-6">
        <div className="tw-relative">
          <div className="tw-absolute tw-inset-0 tw-flex tw-items-center">
            <div className="tw-w-full tw-border-t tw-border-gray-300" />
          </div>
          <div className="tw-relative tw-flex tw-justify-center tw-text-sm">
            <span className="tw-px-2 tw-bg-white tw-text-gray-500">Or continue with</span>
          </div>
        </div>

        <div className="tw-mt-6 tw-grid tw-grid-cols-2 tw-gap-2">
          <button
            type="button"
            onClick={() => handleSocialSignIn('google')}
            className="tw-w-full tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-bg-white tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-50"
          >
            <span className="tw-sr-only">Sign in with Google</span>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="tw-w-5 tw-h-5" viewBox="0 0 48 48">
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
          </button>

          <button
            type="button"
            onClick={() => handleSocialSignIn('facebook')}
            className="tw-w-full tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-bg-white tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-50"
          >
            <span className="tw-sr-only">Sign in with Facebook</span>
            <Facebook className="tw-w-5 tw-h-5 tw-text-primary-600" />
          </button>

        </div>
      </div>

      <p className="tw-mt-2 tw-text-center tw-text-sm tw-text-gray-600">
        Don't have an account?{' '}
        <button
          type="button"
          onClick={() => setAuthState('signUp')}
          className=" tw-justify-center tw-py-2 tw-px-4 tw-border tw-border-transparent tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-white tw-bg-primary-600 hover:tw-bg-primary-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-primary-500"
        >
          Sign up
        </button>
      </p>
    </form>
  );
};