import { z } from 'zod';

export const emailSchema = z.string().email('Invalid email address').toLowerCase();

export const passwordSchema = z
  .string()
  .min(6, 'Password must be at least 6 characters')
  // .regex(/[A-Z]/, 'Password must contain at least one uppercase letter')
  // .regex(/[a-z]/, 'Password must contain at least one lowercase letter')
  // .regex(/[0-9]/, 'Password must contain at least one number')
  // .regex(/[^A-Za-z0-9]/, 'Password must contain at least one special character');

export const codeSchema = z
  .string()
  .min(6, 'Verification code must be 6 characters')
  .max(6, 'Verification code must be 6 characters')
  .regex(/^[0-9]+$/, 'Verification code must contain only numbers');