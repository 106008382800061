import React, { createContext, useContext, useEffect, useState } from 'react';
import { Hub } from '@aws-amplify/core';
import { signOut, getCurrentUser } from 'aws-amplify/auth';
import { AuthUser } from '@aws-amplify/auth';

interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: AuthUser | null;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isLoading: true,
  user: null,
  signOut: async () => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<AuthUser | null>(null);
  
  const checkUser = async () => {
    try {
      const user = await getCurrentUser();
      setIsAuthenticated(true);
      setUser(user);
      checkForReturnUrl();
    } catch (error) {
      setIsAuthenticated(false);
      setUser(null);
      // console.error('User is not logged in:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    checkUser();

    const listener = Hub.listen('auth', ({ payload }) => {
      
      switch (payload.event) {
        case 'signedIn':
          checkUser();
          // if we have a returnUrl, redirect to it
          checkForReturnUrl();
          break;
        case 'customOAuthState': // this means the user has signed in with a social provider
          checkUser();
          checkForReturnUrl();
          break;
        case 'signedOut':
          setIsAuthenticated(false);
          setUser(null);
          break;
      }
    });

    return () => listener();
  }, [checkUser]);


  const checkForReturnUrl = () => {
    const returnUrl = sessionStorage.getItem("bookmarkReturnUrl");
    // if we have a returnUrl, redirect to it after the user logs in
    if (returnUrl) {
      console.log("redirecting to returnUrl:", returnUrl);
      sessionStorage.removeItem("bookmarkReturnUrl");
      window.location.href = returnUrl;
    }
  }

  const handleSignOut = async () => {
    try {
      await signOut();
      setIsAuthenticated(false);
      setUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        user,
        signOut: handleSignOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);