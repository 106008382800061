import React, { useState, useEffect } from 'react';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';

const newsletters = [
  { id: 1, name: 'Tech News Weekly' },
  { id: 2, name: 'Fitness & Health Tips' },
  { id: 3, name: 'Cooking Recipes Digest' },
];

const Newsletter: React.FC = () => {
  const [email, setEmail] = useState('');
  const [subscriptions, setSubscriptions] = useState<number[]>([]);

  useEffect(() => {
    fetchUserEmail();
  }, []);

  const fetchUserEmail = async () => {
    try {
      const user = await getCurrentUser();
      const attributes = await fetchUserAttributes();
      setEmail(attributes.email || '');
    } catch (error) {
      console.error('Error fetching user email:', error);
    }
  };

  const handleSubscriptionChange = (newsletterId: number) => {
    setSubscriptions(prev =>
      prev.includes(newsletterId)
        ? prev.filter(id => id !== newsletterId)
        : [...prev, newsletterId]
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Here you would typically send the subscriptions to your backend
    console.log('Subscribed to:', subscriptions);
    alert('Subscriptions updated successfully!');
  };

  return (
    <div className="tw-max-w-md tw-mx-auto tw-bg-white tw-p-8 tw-rounded tw-shadow">
      <h2 className="tw-text-2xl tw-font-bold tw-mb-4">Newsletter Subscriptions</h2>
      <form onSubmit={handleSubmit}>
        <div className="tw-mb-4">
          <label htmlFor="email" className="tw-block tw-mb-2">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded"
          />
        </div>
        <div className="tw-mb-4">
          <h3 className="tw-font-bold tw-mb-2">Available Newsletters</h3>
          {newsletters.map(newsletter => (
            <div key={newsletter.id} className="tw-flex tw-items-center tw-justify-between tw-mb-2">
              <label htmlFor={`newsletter-${newsletter.id}`}>{newsletter.name}</label>
              <input
                type="checkbox"
                id={`newsletter-${newsletter.id}`}
                checked={subscriptions.includes(newsletter.id)}
                onChange={() => handleSubscriptionChange(newsletter.id)}
                className="tw-ml-2"
              />
            </div>
          ))}
        </div>
        <button type="submit" className="tw-bg-green-500 tw-text-white tw-px-4 tw-py-2 tw-rounded hover:tw-bg-green-600">
          Update Subscriptions
        </button>
      </form>
    </div>
  );
};

export default Newsletter;